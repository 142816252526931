import React, { useEffect, useContext } from "react";
import { BlogContext } from "../utils/UserContext";
import { getBlogs } from "../utils/userHandler";
import SpinLoader from "../components/SpinLoader";
import { Link } from "react-router-dom";

const BlogsPage = () => {
  const { blogs, setBlogs } = useContext(BlogContext);

  useEffect(() => {
    if (!blogs) getBlogs(setBlogs);
     document.querySelector("body").classList.add("body_gray");
     return () => {
       document.querySelector("body").classList.remove("body_gray");
     };
  }, []);
  return (
    <div className="container py-5">
      <h3 className="mb-3">Latest News and Blogs</h3>

      {blogs ? (
        blogs.length > 0 ? (
          <div className="row">
            {blogs.map((blog) => (
              <div key={blog.id} className="col-sm-12 col-md-4 mb-4 ">
                <div className="blog_inner border p-3  bg-white">
                  <div
                    className="mb-2"
                    style={{
                      background: `url(${blog.image}) no-repeat scroll center / cover`,
                      width: "100%",
                      height: "200px",
                    }}
                  />
                  <h6 className="text-uppercase fw-bold">{blog.title}</h6>
                  <div className="small text-muted">
                    {blog.body
                      .substring(0, 300)
                      .replace(/<\/?[^>]+(>|$)/g, "") + " ..."}
                  </div>

                  <Link
                    to={`blog/${blog.title}`}
                    className="small text-capitalize d-flex align-items-center mt-2"
                  >
                    <span>Read more</span>
                    <span className="material-symbols-outlined icon_small">
                      arrow_forward
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          "No content"
        )
      ) : (
        <SpinLoader />
      )}
    </div>
  );
};

export default BlogsPage;
