import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ShareToSocial from "../components/ShareToSocial";
import SpinLoader from "../components/SpinLoader";
import { BlogContext } from "../utils/UserContext";
import { getBlogs } from "../utils/userHandler";
import styles from "./styles/styles.module.css"
import { useMediaQuery } from 'react-responsive';
const BlogDetail = (props) => {
  const { blogs, setBlogs } = useContext(BlogContext);

  const isDesktop = useMediaQuery({
    query : '(min-width:1024px)'
  });

  const [blogInDisplay, setBlogInDisplay] = useState({
    current: null,
    index: null,
  });

  useEffect(() => {
    if (!blogs && blogInDisplay.current === null) getBlogs(setBlogs);
    document.querySelector("body").classList.add("body_gray");
    return () => {
      document.querySelector("body").classList.remove("body_gray");
    };
  }, []);

  useEffect(() => {
    if (blogs) {
      getCurrentStory();
    }
  }, [props.location.pathname, blogs]);

  function getCurrentStory() {
    const index = blogs.findIndex((b) =>
      b.title.match(props.match.params.title)
    );
    setBlogInDisplay({ current: blogs[index], index });
  }

  useEffect(() => {
    let contentArea = document.querySelector(".content");
    if (contentArea)
      document.querySelector(".content").innerHTML = blogInDisplay.current.body;
  }, [blogInDisplay]);

  return (
    <>
      {" "}
      <div div className="container py-5">
        {blogs ? (
          !blogInDisplay.current ? (
            <div className="d-flex align-items-center justify-content-center bg-white text-center flex-column">
              <div
                className="pt-5 fw-bold "
                style={{ fontSize: "70px", color: "#444" }}
              >
                Oops!
              </div>
              <div
                className="text-uppercase fw-bold text-muted"
                style={{ fontSize: "25px" }}
              >
                Page not found
              </div>
              <div className="small text-muted py-3">
                The page you're looking for can not be found on the server.{" "}
                <br />
                Please check the link and try again
              </div>
              <Link
                to="/blogs"
                className="btn btn-primary mb-5 mt-3 d-flex align-items-center"
              >
                <span className="material-symbols-outlined">arrow_back</span>
                Back to blogs
              </Link>
            </div>
          ) : (
            <>
              <div className="w-75 m-auto mb-5 d-flex align-items-center justify-content-between ">
                {!blogInDisplay.index < 1 ? (
                  <Link to={`/blog/${blogs[blogInDisplay.index - 1].title}`}>
                    <div className="d-flex align-items-center">
                      <span className="material-symbols-outlined icon_small">
                        arrow_back
                      </span>
                      <span className="small">Prev</span>
                    </div>
                  </Link>
                ) : (
                  <div className="d-flex align-items-center text-muted">
                    <span className="material-symbols-outlined icon_small">
                      arrow_back
                    </span>
                    <span className="small">Prev</span>
                  </div>
                )}
                <Link
                  to="/blogs"
                  className="btn btn-secondary d-flex align-items-center"
                >
                  <span className="material-symbols-outlined icon_small me-2">
                    other_houses
                  </span>{" "}
                  <span className="small"> Home</span>
                </Link>

                {blogInDisplay.index < blogs.length - 1 ? (
                  <Link to={`/blog/${blogs[blogInDisplay.index + 1].title}`}>
                    {" "}
                    <div className="d-flex align-items-center">
                      <span className="small">Next</span>
                      <span className="material-symbols-outlined icon_small">
                        arrow_forward
                      </span>
                    </div>
                  </Link>
                ) : (
                  <div className="d-flex align-items-center text-muted">
                    <span className="small">Next</span>
                    <span className="material-symbols-outlined icon_small">
                      arrow_forward
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 m-auto bg-white rounded border">
                <div
                  style={{
                    background: `url(${blogInDisplay.current?.image}) no-repeat scroll center / cover`,
                    width: "100%",
                    height: "500px",
                  }}
                />

                <h5 className="border-bottom px-4 p-3 mb-3">
                  {blogInDisplay.current?.title}
                </h5>
                <div className="px-4 small content">No data</div>
              </div>
              <div class={isDesktop ? styles.social_button_wrapper :  styles.social_button_wrapper_mobile}>
                <ShareToSocial url={window.location} />
              </div>
            </>
          )
        ) : (
          <SpinLoader />
        )}
      </div>
    </>
  );
};

export default BlogDetail;


