import React, { useState, useRef } from "react";
import {
  authInWithPhoneNumber,
  onConfirmationResult,
} from "../../../utils/signInWithPhone";
import PhoneInput from "react-phone-input-2";

const AuthWithPhone = ({ isLoggingIn }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [optCodeSent, setCodeOtpSent] = useState(false);
  const otpRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);

  const getOtp = (e) => {
    e.preventDefault();
    const newPhoneNumber = "+" + phoneNumber;
    authInWithPhoneNumber(
      newPhoneNumber,
      setLoading,
      setCodeOtpSent,
      setErrorMessage
    );
  };

  const verifyOtp = () => {
    setErrorMessage(null);
    if (otpRef.current.value.length === 6) {
      setLoading(true);
      //Verify
      onConfirmationResult(otpRef.current.value, setLoading, setErrorMessage);
    } else {
      setErrorMessage(
        "There is a problem with the OTP code you entered. Check the code and try again"
      );
    }
  };

  return (
    <>
      <div>
        {loading && (
          <div className="d-flex align-items-center justify-content-center flex-column mb-3">
            <div
              className="spinner-border spinner-border-md text-secondary"
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
            <div className="small text-muted ms-3"> Loading... </div>
          </div>
        )}

        {errorMessage && (
          <div className="alert alert-danger small">{errorMessage}</div>
        )}

        {!loading && optCodeSent && (
          <div className="alert alert-warning small">
            A one time OTP code has been sent to your phone
          </div>
        )}
        {!optCodeSent && (
          <div className="mb-3">
            <PhoneInput
              inputProps={{ required: true }}
              searchClass=""
              country={"ug"}
              disabled={loading}
              required
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
            />
          </div>
        )}

        {optCodeSent && (
          <input
            type="number"
            placeholder="Enter your OTP code"
            ref={otpRef}
            className="form-control mb-3"
            disabled={loading}
          />
        )}
        <div id="recap"></div>

        <div className="d-grid gap-2">
          {!optCodeSent && (
            <button
              className="btn btn-md btn-primary mb-3 full"
              disabled={loading}
              onClick={(e) => getOtp(e)}
            >
              {isLoggingIn ?  "Sign in" : "Sign up"}
            </button>
          )}

          {optCodeSent && (
            <>
              <button
                className="btn btn-md btn-primary mb-3 full"
                disabled={loading}
                onClick={verifyOtp}
              >
                Verifiy Otp
              </button>
              <div
                className="small text-muted pointer"
                onClick={() => {
                  setLoading(false);
                  setCodeOtpSent(false);
                }}
              >
                Resend OTP code
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AuthWithPhone;
