import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import MainLayout from "../layouts/MainLayout";
import AuthForm from "../components/form/widgets/Auth.form";
import AnnonymousRoutes from "../components/protectedRoutes/AnnonymousRoutes";
import CartPage from "./Cart.page";
import Checkout from "./Checkout.page";
import ProtectedRoutes from "../components/protectedRoutes/ProtectedRoutes";
import TransactionComplete from "./TransactionCompleted.page";
import MyAccount from "./MyAccount.page";
import Orders from "./Orders.page";
import OrderDetails from "./OrderDetails.page";
import TrackOrder from "./TrackOrder.page";
import ProductsPage from "./Products.page";
import PageNotFound from "./PageNotFound.page";
import HelpCenter from "./HelpCenter";
import Faqs from "./Faqs";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUs from "./AboutUs";
import { useEffect } from "react";
import BlogsPage from "./Blogs.page";
import BlogDetail from './BlogDetail.page';
import ReturnPolicy from "./ReturnPolicy";
import DeleteAccount from "./DeleteAccount";

const MainRoutes = () => {
  useEffect(()=>{window.scrollTo(0, 0);},[window.location.pathname]);
  return (
    <MainLayout>
      <Switch>
        <Route exact path="/" component={Home} />
        <AnnonymousRoutes path="/user" component={AuthForm} />
        <ProtectedRoutes path="/cart" component={CartPage} />
        <ProtectedRoutes path="/checkout" component={Checkout} />
        <ProtectedRoutes
          path="/transaction-complete"
          component={TransactionComplete}
        />
        <ProtectedRoutes path="/customer/account" component={MyAccount} />
        <ProtectedRoutes path="/customer/orders" component={Orders} />
        <ProtectedRoutes
          path="/customer/order/details"
          component={OrderDetails}
        />
        <ProtectedRoutes path="/customer/order/track" component={TrackOrder} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/help-center" component={HelpCenter} />
        <Route path="/frequently-asked-questions" component={Faqs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/return-policy" component={ReturnPolicy} />
        <Route path="/how-to/delete-account" component={DeleteAccount} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/blogs" component={BlogsPage} />
        <Route path="/blog/:title" component={BlogDetail} />
        <Route component={PageNotFound} />
      </Switch>
    </MainLayout>
  );
};

export default MainRoutes;
