const firebaseErrorHandler = (errorCode) => {
  switch (errorCode) {
    case "auth/network-request-failed":
      return "Network connection failed";
    case "auth/user-not-found":
      return "Account does not exist";
    case "auth/wrong-password":
      return "Wrong username or password";
    case "auth/email-already-in-use" :
        return "User with email already exists";
    case "auth/invalid-phone-number":
      return "Invalid phone number provided. Check number and try again. Correct format is +256xxxxxxxxx";
      case "recaptha-rendered":
        return "An error occured. Please reload the page and try again"

    default:
      return "Something went wrong";
  }
};

export default firebaseErrorHandler;
