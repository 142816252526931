import React,{useEffect} from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <>
      <Helmet title={`Privacy policy | ${process.env.REACT_APP_SITE_NAME}`} />
      <div className="container mt-5">
        <h3>Privacy Policy</h3>
        <div className="small">
          Teddys Fresh Market is a service provided Teddys Fresh Market Limited.
          <br />
          The terms you or user refer to each person visiting 
            http://www.teddysfreshmarket.com
          . The terms we and our website refer to Teddys Fresh Market.
          Protecting your personal data is very important to us. By visiting our
          website, you provide us with some personal data. This policy explains
          how we use it. You can visit our website and explore it without
          providing us with personal data.
          <br />
          <br />
          <strong>What information do we collect about you?</strong>
          <br />
          We only collect personal information from you when you order goods or
          services on our website, when you complete any customer survey, or
          through cookies. We collect this information in order to enable
          orders, such as name, address, phone number, or payment information.
          <br />
          <br />
          <strong>Cookies and Google Analytics</strong>
          <br />
          Accepting cookies is not necessary to visit our website, but mandatory
          in order to complete correctly an order.
          <br />
          <br />
          <strong>What are cookies?</strong> <br />
          Cookies are small text files placed on your computer while visiting
          websites. They are necessary to have websites work more efficiently or
          to provide information to the website’s owners. We collect cookies to
          remember your IP address, so that you can save time when coming back
          on our website. To get more information about cookies, please
          visit www.allaboutcookies.org.
          <br />
          <br />
          <strong>Teddysfreshmarket.com</strong> uses Google Analytics, a web
          analytics service provided by Google, Inc. ("Google"). Google
          Analytics uses "cookies" (see above).
          <br />
          <br />
          The information generated by the cookie about your use of the website
          will be transmitted to and stored by Google on servers in the United
          States. If this website anonymizes IP addresses, your IP address will
          be truncated by Google within a EU member state or other EEA state
          before being transmitted to the US. Only in exceptional situations
          will your full IP address be transmitted to Google servers in the
          United States and truncated there.
          <br />
          <br />
          Google will use this information for the purpose of evaluating your
          use of the website, compiling reports on website activity for website
          operators and providing other services relating to website activity
          and internet usage. Google will not associate your IP address with any
          other data held by Google.
          <br />
          <br />
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser, however please note that if you do this you
          may not be able to use the full functionality of this website. By
          using this website, you consent to the processing of data about you by
          Google in the manner and for the purposes set out above. You can also
          prevent Google from collecting information (including your IP address)
          via cookies and processing this information by downloading this
          browser plugin and installing it:
          <Link to={{ pathname: "https://tools.google.com/dlpage/gaoptout",target:"_blank"}}>
            https://tools.google.com/dlpage/gaoptout
          </Link>
           .
          <br />
          <br />
          <strong>How will we use the information we collect from you?</strong>
          <br />
          We collect and use your personal data on secured servers. We ask third
          organisms to check and certify our confidentiality
          principles.Information that we collect from you is used to process
          your order and to manage your account. We may also use your
          information to email you about other products or services that we
          think may be of interest to you.
          <br />
          <br />
          In processing your order we may send your information to credit
          reference and fraud prevention agencies, or to third companies.
          <br />
          From time to time we may send your information to third parties which
          we consider may have goods or services which are of interest to you.
          <br />
          <br />
          If you do not wish to be contacted by third parties, please email us
          at info@teddysfreshmarket.com Access to your informationAt any time,
          you can request a copy of the information we hold on you. Simply
          contact us a info@teddysfreshmarket.com We will ask you a small charge
          in order to process your request. Other WebsitesOur website may have
          links to other websites. This privacy policy only applies to this
          website. You should therefore read the privacy policies of the other
          websites when you are using those sites.
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy