import clsx from 'clsx';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import styles from './styles/styles.module.css';

const HelpCenter = () => {


  const helpCat = [
    {id:1,title:"Getting started",description:"Set up your katale account and start shopping",icon: "rocket_launch"},
    {id:2,title:"Placing an order",description:"How to Place items in cart and checking out",icon:"local_mall" },
    {id:3,title:"How do i pay",description:"How do i pay for my items during checkout",icon:"paid" },
    {id:4,title:"Cancel my order",description:"Cancelling an order after it has been confirmed",icon: "backspace"},
    {id:6,title:"Cancel my account",description:"Cancelling my user account and deleting all the data",icon:"delete_forever" },
  ]
  return (
    <>
      <Helmet title={"Help Center | " + process.env.REACT_APP_SITE_NAME} />
      <div className="container">
        <div className="text-center py-5 ">
          <h5>Need help?</h5>
          <span className="small text-muted">
            Our help center is designed to make you have a good user experience
          </span>
        </div>
        <div className="row text-muted">
          {helpCat.map((item) => (
            <div className="col-sm-12 col-md-3" key={item.id}>
              <div className={clsx(styles.hblock, "p-4 mb-4")}>
                  <span class={clsx(styles.hicon, "material-symbols-outlined mb-2")}>{item.icon}</span>
                <div className={styles.htitle}>{item.title} </div>
                <div className={clsx(styles.hdec, "text-muted")}>
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default HelpCenter

