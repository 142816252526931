import clsx from "clsx";
import React from "react";
import bgSerpia from "../assets/images/bg-serpia.png";
import giftBag from '../assets/images/giftbag.png';
import styles from './styles/strip.module.css'
import a from '../assets/icons/a.png';
import s from '../assets/icons/s.png';
import p from '../assets/icons/p.png';
import n from '../assets/icons/n.png';
import { useMediaQuery } from 'react-responsive';

const BestFoodComponent = () => {
  const data = [
    {
      num: "01.",
      title: "always fresh",
      icon: a,
      info: `The Fresh in our name is not only an adjective but also a promise that we will deliver nothing short of Fresh`,
    },
    {
      num: "02.",
      title: "super healthy",
      icon: s,
      info: `Our customers’ health is a priority to us.  It gives us great joy to play a part, however small, in promoting good nutrition for our clients and their families.`,
    },
    {
      num: ".03",
      title: "premium quality",
      icon: p,
      info: `We source the highest quality products. We also understand that in many ways, quality is a personal preference. That’s why we live by the Mantra Listen, Learn, Deliver. We listen to each client’s needs and give them exactly what they require`,
    },
    {
      num: ".04",
      title: "How you want it",
      icon: n,
      info: `Ripe, raw, semi-ripe, soft, hard, whole, chopped, sliced, grated etc. we give you what you want exactly how you want it.`,
    },
  ];
  const isSmallScreen  = useMediaQuery({ query : '(max-width : 767px)'});
  const isBigScreen = useMediaQuery({ query : '(min-width : 768px)'})

  return (
    <div
      className={clsx(styles.bestFood, "pb-5")}
      style={{ backgroundImage: `url(${bgSerpia})` }}
    >
      <div className="text-center position-relative">
        <h4 className="text-capitalize">Why Teddy's?</h4>
        <p className="small">
          We guarantee to deliver quality, healthy and fresh products to all our customers.
        </p>
      </div>
      <div className="row container pb-5 position-relative">
        <div className="col-sm-12 col-md-4">
          {data.slice(0, 2).map((item, index) => (
            <div key={index} className="d-flex align-items-start text-start">
              <img src={item.icon} width="40" className="mt-2" alt={item.info} />
              <div className="ms-3 mb-3">
                <div className={styles.num}>{item.num}</div>
                <div
                  className={clsx(styles.title, "text-uppercase fw-bold fs-5")}
                >
                  {item.title}
                </div>
                <div className={clsx(styles.info)}>{item.info}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-center">
          <img src={giftBag} width="60%" alt="best food grown" />
        </div>
        <div className="col-sm-12 col-md-4">
          {data.slice(2, 4).map((item, index) => (
            <div
              key={index}
              className={clsx(
                "d-flex align-items-start ",
                isBigScreen && "text-end",
                isSmallScreen && "flex-row-reverse"
              )}
            >
              <div className="me-3 mb-3">
                <div className={styles.num}>{item.num}</div>
                <div
                  className={clsx(styles.title, "text-uppercase fw-bold fs-5")}
                >
                  {item.title}
                </div>
                <div className={clsx(styles.info)}>{item.info}</div>
              </div>
              <img src={item.icon} width="40" className="mt-2" alt={item.info} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestFoodComponent;
