import React, { useEffect, useState, useContext, useMemo } from "react";
import fruitIcon from "../assets/icons/fruits.png";
import vegeIcon from "../assets/icons/vegetables.png";
import offersIcon from "../assets/icons/offers.png";
import ryanIcon from "../assets/icons/ryans.png";
import spiceIcon from "../assets/icons/spice.png";
import stapleIcon from "../assets/icons/staple.png";
import meatFishIcon from "../assets/icons/meat-fish.png";
import Styles from "./styles/product.module.css";
import clsx from "clsx";
import SpinLoader from "./SpinLoader";
import { ProductContext } from "../utils/UserContext";
import { fetchGroceries } from "../utils/userHandler";

import ProductCard from "./ProductCard";
import { Link } from "react-router-dom";
import NoResults from "./NoResults.component";

const Products = () => {
  const tabs = [
    { title: "vegetables", icon: vegeIcon, id: 0 },
    { title: "fruits", icon: fruitIcon, id: 1 },
    { title: "Meats & fish", icon: meatFishIcon, id: 2 },
    { title: "offers", icon: offersIcon, id: 3 },
    { title: "Ryans garden", icon: ryanIcon, id: 4 },
    { title: "Cooking essentials", icon: spiceIcon, id: 5 },
    { title: "More groceries", icon: stapleIcon, id: 6 },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const { groceries, setGroceries } = useContext(ProductContext);
  const [activeGroceryList, setActiveGroceryList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (groceries.length === 0) {
      setIsFetching(true);
      fetchGroceries(setGroceries, setIsFetching);
    }else{
      const filterInitialList = groceries.filter((grocery) =>
          grocery.category.toLowerCase().includes(tabs[0].title.toLowerCase().split(' ')[0]));
        setActiveGroceryList(filterInitialList);
    }
     
  }, [groceries]);



  // useMemo(() => {
  //   if (groceries.length > 0) {
  //     switch (activeTab) {
  //       case 0:
  //         const vegetablesList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("vegetables")
  //         );
  //         return setActiveGroceryList(vegetablesList);

  //       case 1:
  //         const fruitsList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("fruits")
  //         );
  //         return setActiveGroceryList(fruitsList);

  //       case 2:
  //        const meatList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("meats")
  //         );
  //         return setActiveGroceryList(meatList);
  //       case 3:
  //         const offersList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("offers")
  //         );
  //         return setActiveGroceryList(offersList);
  //       case 4:
  //         const ryansList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("ryans garden")
  //         );
  //         return setActiveGroceryList(ryansList);
  //       case 5:
  //         const cookingList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("cooking essentials")
  //         );
  //         return setActiveGroceryList(cookingList);
  //       case 6:
  //        const moreList = groceries.filter((grocery) =>
  //           grocery.category.toLowerCase().includes("more groceries")
  //         );
  //         return setActiveGroceryList(moreList);
  //       default:
  //         return setActiveGroceryList([]);
  //     }
  //   }
  // }, [activeTab, groceries]);

  const onActiveTabChange = (filterString) => {
      const activesList = groceries.filter((grocery) =>
        grocery.category.toLowerCase().includes(filterString.toLowerCase().split(' ')[0])
      );
      setActiveGroceryList(activesList);
  };

  return (
    <>
      <div className="container text-center mt-5">
        <h4 className="">Discover Our Products</h4>
        <p className="text-muted small">
          Delivered fresh to your doorstep.
        </p>
        <div className={clsx(Styles.tabs_wrapper,"d-flex align-items-center justify-content-between mt-5 hide-scrollbar")}>
          {tabs.map((tab) => (
            <div
              className={clsx(
                activeTab === tab.id ? Styles.activeTab : "",
                Styles.tab,
                "d-flex align-items-center justify-content-center flex-column pb-2 pointer"
              )}
              key={tab.id}
              onClick={() => {
                setActiveTab(tab.id);
              onActiveTabChange(tab.title);
              }}
            >
              <img src={tab.icon} width="100px" alt={tab.title} />
              <div className="small text-capitalize">{tab.title}</div>
            </div>
          ))}
        </div>
        {isFetching === true ? (
          <SpinLoader />
        ) : (
          <>
            {activeGroceryList.length === 0 ? (
              <NoResults />
            ) : (
              <ProductCard groceries={activeGroceryList.slice(0)} />
            )}
            <div className="text-center">
              <Link
                to="/products"
                className="btn btn-sm btn-primary text-uppercase"
              >
                <span className="small">View all products</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Products;
