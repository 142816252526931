import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

const ReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title={`Return policy | ${process.env.REACT_APP_SITE_NAME}`} />
      <div className="container mt-5">
        <h3>Return Policy</h3>
        <div className="small">
          At Teddy's Fresh Market, we are committed to providing our customers
          with the highest quality fresh groceries and an exceptional shopping
          experience. We understand that, on rare occasions, issues may arise
          with a product or order. This Returns Policy outlines our procedures
          for addressing such situations and ensuring your satisfaction.
          <br />
          <br />
          <h4>
            <strong>1. Defective or Unsatisfactory Products</strong>
          </h4>
          <br />
          <p>
            1.1 If you receive a product that is defective, damaged, or
            unsatisfactory, please contact our customer support team within 2
            hours of receiving your order.
          </p>
          <p>
            1.2 Some perishable goods cannot be returned if they are defective
            or unsatisfactory. However, they can be replaced.
          </p>
          <p>
            1.3 To initiate a return or replacement, simply send us a clear
            picture of the defective or unsatisfactory product via email at
            info@teddysfreshmarket.com or on our WhatsApp business line{" "}
          </p>
          <p>
            1.4 Our customer support team will review your request and, if
            eligible, arrange for a replacement of the item.
          </p>
          <p>
            1.5 We aim to process your replacement request on the same day after
            receiving the necessary information.
          </p>
          <h4>
            <strong>2. Refunds</strong>
          </h4>
          <p>
            2.1 In certain cases, if a replacement is not available or suitable,
            we may offer a refund for the purchase price of the returned item.
          </p>
          <p>
            2.2 Refunds will be processed using the original payment method used
            for the order.
          </p>
          <p>
            2.3 Please allow up to 2 business days for the refund to reflect in
            your account, depending on your payment provider.
          </p>
          <h4>
            <strong>3. Customer Support</strong>
          </h4>
          <br />
          <p>
            3.1 Our dedicated customer support team is available to assist you
            with any returns or concerns. You can reach us vi
          </p>
          <h4>
            <strong>4. Feedback</strong>
          </h4>
          <br />
          <p>
            4.1 Your feedback is valuable to us. If you have any concerns or
            suggestions regarding our products or services, please feel free to
            share them with our customer support team. Your input helps us
            continually improve.
          </p>
          <h4>
            <strong>5. Policy Updates</strong>
          </h4>
          <br />
          <p>
            5.1 Teddy's Fresh Market reserves the right to update or modify this
            Returns Policy at any time. Please review this policy periodically
            to stay informed of any changes.
          </p>
          <br />
          <p>
            We appreciate your trust in Teddy's Fresh Market and thank you for
            choosing us as your preferred grocery delivery service. We are
            committed to ensuring that your shopping experience with us is as
            smooth and satisfying as possible.
          </p>
          <p className=" text-muted">Policy Updated on 10/07/2023</p>
        </div>
      </div>
    </>
  );
};

export default ReturnPolicy;
