import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../utils/UserContext";
import Input from "../components/form/widgets/Input";
import countries from "../utils/countries.json";
import PhoneInput from "react-phone-input-2";
import SelectFilter from "../components/form/widgets/SelectFilter";
import {
  getUserData,
  updateUserData,
  updateUserPicture,
} from "../utils/userHandler";
import regionsList from "../utils/regionsList.json";
import firebaseErrorHandler from "../utils/firebaseErrorHandler";
import MessageAlert from "../components/alerts/MessageAlert";
import ErrorAlert from "../components/alerts/ErrorAlert";
import SpinLoader from "../components/SpinLoader";
import ReactLoading from "react-loading";
import UserSidePanel from "../components/Nav/UserSidePanel";
import { Helmet } from "react-helmet";
import DummyAvatar from "../assets/images/dummy.jpg";

const MyAccount = ({ location }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [userData, setUserData] = useState(currentUser);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [uploading, setUploading] = useState({ status: false, progress: 0 });
  const [error, setError] = useState(null);


  useEffect(() => {
    window.scrollTo(0,0);
    document.querySelector("body").classList.add("body_gray");
    return () => {
      document.querySelector("body").classList.remove("body_gray");
    };
  }, []);
  useEffect(() => {
    setUserData(currentUser);
  }, [currentUser]);

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    //check if fields are set

    if (!userData.userId) userData.userId = currentUser.uid;
    if (!userData.accountType) userData.accountType = "Customer";

    if (!userData.email) userData.email = currentUser.email;
    if (!userData.token) userData.token = currentUser.accessToken;
    if (!userData.joinDate) userData.joinDate = null;

    if (!userData.locality || userData.locality === "")
      return setError("Location field is required");
    if (!userData.address || userData.address === "")
      return setError("Address field is required");
    if (!userData.countryName || userData.countryName === "")
      return setError("Country field is required");
    if (!userData.displayName || userData.displayName === "")
      return setError("Display name field is required");
    if (!userData.phoneNumber || userData.phoneNumber === "")
      return setError("Phone field is required");

    setLoading(true);
    const response = await updateUserData(
      currentUser.uid,
      userData,
      currentUser.accessToken
    );

    if (response.status === "successful") {
      await getUserData(currentUser.uid, currentUser, setCurrentUser);
      setMessage("Account updated successfully");
    } else {
      const erroMessage = firebaseErrorHandler(response.code);
      setError(erroMessage);
    }
    setLoading(false);
    return;
  };
  const onUploadChange = async (e) => {
    const image = e.target.files[0];
    if (image) {
      let response = await updateUserPicture(
        userData.uid,
        image,
        setUploading,
        setCurrentUser
      );

      if (response.status === "failed") {
        let errorMessage = firebaseErrorHandler(response.code);
        return setError(errorMessage);
      } else {
        return setMessage("Picture uploaded successfully");
      }
    }
  };


  return (
    <>
      <Helmet title={"My account | " + process.env.REACT_APP_SITE_NAME} />
      <div className="container">
        {uploading.status && (
          <div className="progress w-75 m-auto">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: uploading.progress + "%" }}
              aria-valuenow="100"
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {uploading.progress} %
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-12 col-md-3">
            <UserSidePanel />
          </div>
          <div className="col-sm-12 col-md-9">
            {userData ? (
              <div className="border rounded bg-white mt-5">
                <h6 className="border-bottom px-4 p-3 mb-3">Edit my account</h6>
                <div className="px-4">
                  {location.state?.message && (
                    <div className="alert alert-warning my-4 small">
                      {location.state?.message}
                    </div>
                  )}
                  {message && (
                    <div className="small">
                      <MessageAlert message={message} setMessage={setMessage} />
                    </div>
                  )}
                  {error && (
                    <div className="small">
                      <ErrorAlert errorMessage={error} setError={setError} />
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    {uploading.status ? (
                      <span
                        className="d-flex align-items-center justify-content-center me-3"
                        style={{
                          width: "60px",
                          height: "60px",
                          display: "block",
                          backgroundColor: "#00000029",
                          borderRadius: "50%",
                        }}
                      >
                        <ReactLoading type="bars" height={25} width={25} />
                      </span>
                    ) : (
                      <img
                        className="rounded-circle me-3"
                        width="80"
                        height="80"
                        src={
                          userData?.imageUrl === "default" ||
                          !userData?.imageUrl
                            ? DummyAvatar
                            : userData?.imageUrl
                        }
                      />
                    )}

                    <label
                      className="btn btn-secondary btn-sm me-2"
                      htmlFor="avatar"
                    >
                      <span className="small">Upload</span>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      className="hide-input"
                      id="avatar"
                      disabled={uploading.status}
                      required
                      onChange={(e) => onUploadChange(e)}
                    />

                    {uploading.status ||
                      (loading && (
                        <ReactLoading
                          type="bars"
                          width={30}
                          height={30}
                          color="#25441B82"
                        />
                      ))}
                  </div>
                  <div className="border-top mt-3 pt-3 row">
                    <Input
                      label="Display name"
                      name="displayName"
                      id="displayName"
                      placeholder="Enter full name"
                      onChange={onChange}
                      value={userData?.displayName || ""}
                      size="col-md-6"
                      required
                      disabled={loading}
                    />
                    {!currentUser.email && (
                      <Input
                        label="Email address"
                        name="email"
                        placeholder="Enter your email address"
                        onChange={onChange}
                        value={userData?.email || ""}
                        size="col-md-6"
                        required
                        disabled={loading}
                      />
                    )}
                    <div className="col-sm-12 col-md-6">
                      <label className="form-label small">Phone</label>
                      <PhoneInput
                        country={"ug"}
                        onlyCountries={["ug"]}
                        value={userData?.phoneNumber}
                        disabled={loading}
                        onChange={(phone) => {
                          setUserData({ ...userData, phoneNumber: phone });
                        }}
                      />
                    </div>{" "}
                    <SelectFilter
                      name="countryName"
                      id="country"
                      label="Country Name"
                      list={countries.map((country) => country.name)}
                      placeholder="Select your nationality"
                      value={userData?.countryName}
                      size="col-md-6"
                      onChange={onChange}
                      required
                      disabled={loading}
                    />
                    <SelectFilter
                      name="adminArea"
                      id="adminArea"
                      label="Region"
                      list={regionsList}
                      size="col-md-6"
                      value={userData?.adminArea}
                      placeholder="Select your region"
                      onChange={onChange}
                      required
                      disabled={loading}
                    />
                    <Input
                      id="address"
                      name="address"
                      onChange={onChange}
                      required
                      value={userData?.address || ""}
                      placeholder="Ex. Ntinda, Stratcher road"
                      size="col-md-6"
                      label="Address"
                      disabled={loading}
                    />
                    <Input
                      id="locality"
                      name="locality"
                      onChange={onChange}
                      required
                      value={userData?.locality || ""}
                      placeholder="Enter your locality"
                      size="col-md-6"
                      label="Locality"
                      disabled={loading}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-end mb-4">
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      disabled={loading}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-sm ms-3"
                      disabled={loading}
                      onClick={(e) => onSubmit(e)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <SpinLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
