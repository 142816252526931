import clsx from "clsx";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Faqs = () => {
  const faqs = [
    {
      question: "How much is the delivery cost?",
      answer:
        "On top of the cost of the goods you are purchasing, we charge 10,000 shillings as a flat delivery fee. This will be included in your total bill.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "You may pay cash on delivery, a bank transfer or MTN or Airtel mobile money.",
    },
    {
      question: "Do you deliver to my Area?",
      answer:
        "We deliver in the greater Kampala area including areas of Wakiso, Mukono and Mpigi.",
    },
    {
      question: "Can I change the order I placed?",
      answer:
        "You can change your order up until you are notified that the delivery person has set off.",
    },
    {
      question: "My order is missing an Item?",
      answer: `Much as this is a rarity, please contact us on .
          We will deliver the item promptly at no extra cost.`,
    },
    {
      question: "How about returns?",
      answer: "Please refer to our return policy here.",
    },
    {
      question: "Which language do the delivery people speak?",
      answer:
        "English, Luganda and other local languages depending on the delivery person.",
    },
    {
      question: "Will you call for location information?",
      answer:
        "Yes. Luckily when we deliver once, we endeavour to record that location so repeat customers need not worry about excessive calls for directions. ",
    },
  ];
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    document.querySelector("body").classList.add("body_gray");
    return () => {
      document.querySelector("body").classList.remove("body_gray");
    };
  }, []);

  return (
    <>
      <Helmet title={`Faqs | ${process.env.REACT_APP_SITE_NAME}`} />
      <div className="container py-5">
        <div className="w-75 m-auto bg-white rounded border">
          <h6 className="border-bottom px-4 p-3 mb-3">
            Frequently asked questions
          </h6>
          <div className="px-4 py-3">
            {faqs.map((faq, index) => (
              <div
                className={clsx(
                  index === activeTab ? "qcollapsed" : null,
                  "qtab p-3 mb-3"
                )}
                key={index}
              >
                <div
                  className="faq_qn pointer small fw-bold text-secondary d-flex align-items-center justify-content-between"
                  onClick={() => setActiveTab(index)}
                >
                  <span>{faq.question}</span>{" "}
                  <span className="material-symbols-outlined text-muted">
                    {index === activeTab ? "expand_less" : "expand_more"}
                  </span>
                </div>
                <div className="faq_ans small text-dark">{faq.answer}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
