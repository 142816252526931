import React from 'react';

import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon} from "react-share";
import { useMediaQuery } from 'react-responsive';
const ShareToSocial = ({ url }) => {

    const iconSize = 40;

    const isDesktop =  useMediaQuery({query : '(min-width:1024px)'});

    const style_desktop = {
        display:"flex",
        alignItems:"start",
        flexDirection:"column"
    }



  return (
    <div class="social_share" style={isDesktop ? style_desktop: null }>
      <FacebookShareButton url={url}>
        <FacebookIcon size={iconSize} />
      </FacebookShareButton>

      <TwitterShareButton url={url}>
        <TwitterIcon size={iconSize} />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={iconSize} />
      </LinkedinShareButton>
    </div>
  );
  
}

export default ShareToSocial