import clsx from "clsx";
import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { BlogContext } from "../utils/UserContext";
import { getBlogs } from "../utils/userHandler";

import Styles from "./styles/strip.module.css";

const BlogComponent = () => {
  const { blogs, setBlogs } = useContext(BlogContext);

  useEffect(() => {
    if (!blogs) getBlogs(setBlogs);
  }, []);

  return (
    <>
      {blogs ? (
        blogs.length > 0 && (
          <div className="container pb-5">
            <h4 className="text-center py-4">Latest Post</h4>
            <div className="row">
              {blogs.slice(0, 4).map((blog, index) => (
                <Link
                  key={index}
                  to={`/blog/${blog.title}`}
                  className="col-ms-12 col-md-3"
                >
                
                    <div
                      style={{
                        background: `url(${blog.image}) no-repeat scroll center/ cover`,
                        width: "100%",
                        height: "171px",
                        borderRadius: "5px",
                      }}
                    />
                    <div
                      className={clsx(
                        Styles.blog_title,
                        "fw-bold small pt-3 pb-2"
                      )}
                    >
                      {blog.title}
                    </div>
                    <div className={clsx(Styles.blog_desc, "text-muted")}>
                      {blog.body
                        .substring(0, 100)
                        .replace(/<\/?[^>]+(>|$)/g, "")}
                    </div>
                  
                </Link>
              ))}
            </div>
            <div className="text-center">
              <Link
                to="/blogs"
                className={clsx(
                  Styles.moreBtn,
                  "btn btn-sm  text-uppercase text-white mt-5 px-3"
                )}
              >
                Read more
              </Link>
            </div>
          </div>
        )
      ) : (
        <div className="d-flex align-items-center flex-column py-5">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="small text-muted mt-2">Loading...</div>
        </div>
      )}
    </>
  );
};

export default BlogComponent;
