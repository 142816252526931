import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    document.querySelector("body").classList.add("body_gray");
    return () => {
      document.querySelector("body").classList.remove("body_gray");
    };
  }, []);

  return (
    <div div className="container py-5">
      <div className="w-75 m-auto bg-white rounded border">
        <h5 className="border-bottom px-4 p-3 mb-3">Our Story</h5>
        <div className="px-4 small">
          <p>
            Four years ago, we started Teddy&rsquo;s Fresh Market to supply
            fresh food to shoppers who value convenience and quality.
          </p>
          <p>
            More than 10,000 orders later, we have grown into a mature business
            that values customer satisfaction beyond none else.
          </p>
          <p>
            It is this journey that led us to the mantra we live by; we Listen,
            Learn and Deliver.
          </p>
          <p>
            We source, pack and deliver the groceries in-house. No third parties
            involved.
          </p>
          <p>
            <strong>FRESHNESS &bull; VALUE &bull; CONVENIENCE</strong>
          </p>
          <ul>
            <li>Fruits</li>
            <li>Vegetables</li>
            <li>Meats</li>
            <li>Fish</li>
            <li>Cooking Essentials</li>
            <li>Spices</li>
            <li>Dried Foods</li>
            <li>Other Groceries</li>
          </ul>
          <h6 className="border-bottom pb-2 mb-3 fw-bold">
            <br />
            WHAT WE DO
          </h6>
          <p>
            <strong>Grocery Delivery</strong>
          </p>
          <p>
            Businesses including restaurants, hotels, caterers, schools, private
            and government institutions etc. who deal with Teddy&rsquo;s are
            looking for efficiency in cost and service.
          </p>
          <p>
            We work with our clients and tailor fresh grocery and other produce
            supply solutions that are cost effective, time sensitive and quality
            proof.
          </p>
          <p>
            We take the stress of sourcing, quality assurance and deliv- ery
            away such that our clients concen- trate on doing what they do best.
          </p>
          <p>
            <strong>How to Order</strong>
          </p>
          <p>
            Reach out to our customer relations team on +256 776 092 395 to
            discuss and design a supply solution that is best suited foryour
            business.
            <br />
            <br />
            <strong>You may also make orders through our</strong>
          </p>
          <p className="d-flex align-items-center">
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "19px !important",
                marginRight: "5px",
                display: "block",
                fontSize: "17px",
              }}
            >
              email
            </span>
            orders@teddysfreshmarket.com
          </p>
          <p className="d-flex align-items-center">
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "19px !important",
                marginRight: "5px",
                display: "block",
                fontSize: "17px",
              }}
            >
              phone
            </span>{" "}
            Call us on +256 783 092 048 with your order or enquiry.
          </p>
          <p className="d-flex align-items-center">
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "19px !important",
                marginRight: "5px",
                display: "block",
                fontSize: "17px",
              }}
            ></span>
            Send us a WhatsApp on +256 759 484 110
          </p>

          <h6 className="border-bottom pb-2 mb-3 fw-bold">
            <br />
            WHY US
          </h6>
          <ol>
            <li>
              Timely delivery on various ordering platforms to suit your
              business style.
              <br />
              Order through App, WhatsApp, Direct calls, email and get your
              supplies promptly delivered.
            </li>
            <br />
            <li>
              <p>
                Value added groceries.
                <br />
                When take the the hussle of fresh processing away such that you
                concentrate on what you do best. Our processing staff are
                trained to maintain the highest standards for food processing
                hygiene.
              </p>
            </li>
            <li>
              <p>
                Industry data.
                <br />
                Access to our data allows for proper planning and budgeting
              </p>
            </li>
            <li>
              <p>
                Quality assurance.
                <br />
                We absorb the risk associated with supplies management
              </p>
              <br />
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
