import React from "react";
import Styles from "./styles/strip.module.css";
import bg from "../assets/images/bg-serpia.png";
import f1 from "../assets/icons/f1.png";
import f2 from "../assets/icons/f2.png";
import f3 from "../assets/icons/f3.png";
import call from "../assets/icons/call.png";
import pin from "../assets/icons/pin.png";
import whtIcon from "../assets/icons/whatsapp-icon.png";
import mail from "../assets/icons/mail.png";
import clock from "../assets/icons/clock.png";
import clsx from "clsx";
import paymentsImage from "../assets/images/payments.png";
import googlePlayButton from "../assets/images/google_play_button.png";
import { Link } from "react-router-dom";
import facebookIcon from "../assets/icons/social/facebook_icon.png";
import twitterIcon from "../assets/icons/social/twitter_icon.png";
import tiktokIcon from "../assets/icons/social/tiktok_icon.png";
import instagramIcon from "../assets/icons/social/instagram_icon.png";
import youtubeIcon from "../assets/icons/social/youtube_icon.png";

const Footer = () => {
  const footerTop = [
    {
      icon: f1,
      title: "Flexible payment",
      text: "With just a press of a button you will be ready to go.",
    },
    {
      icon: f2,
      title: "Delivery at your doorstep",
      text: "Our experienced riders will find you no matter where you are.",
    },
    {
      icon: f3,
      title: "Quality service guaranteed",
      text: "We make a promise to deliver fresh, healthy products.",
    },
  ];
  const customercare = [
    { name: "contact us", link: "#" },
    { name: "help and advice", link: "/frequently-asked-questions" },
    { name: "Shipping and return", link: "#" },
    { name: "terms and conditions", link: "#" },
    { name: "return policy", link: "/return-policy" },
  ];
  const information = [
    { name: "My account", link: "/customer/account" },
    { name: "payment and return", link: "#" },
    { name: "catalogue online", link: "#" },
    { name: "refund policy", link: "#" },
    { name: "delete account", link: "/how-to/delete-account" },
  ];
  const about = [
    { name: "who we are", link: "/about-us" },
    { name: "corporate resposibility", link: "#" },
    { name: "careers", link: "#" },
    { name: "privacy policy", link: "/privacy-policy" },
  ];
  const contact = [
    { icon: call, text: "(+256 783 092 048, +256 759 484 110 )" },
    { icon: whtIcon, text: "+256 759 484 110" },
    { icon: mail, text: "info@teddysfreshmarket.com" },
    { icon: pin, text: "Nakawa traders' market" },
    { icon: clock, text: "opening hours: 08:00am - 05:00Pm" },
  ];
  const social = [
    {
      name: "facebook",
      link: "https://www.facebook.com/TeddysFreshMarket",
      icon: facebookIcon,
    },
    {
      name: "twitter",
      link: "https://twitter.com/teddys_market",
      icon: twitterIcon,
    },
    {
      name: "instagram",
      link: "https://www.instagram.com/teddysfreshmarket/",
      icon: instagramIcon,
    },
    // { name: "tiktok", link: "https://tiktok.com", icon: tiktokIcon },
    // { name: "youtube", link: "https://youtube.com", icon: youtubeIcon },
  ];
  return (
    <>
      <div
        className={Styles.footerTop}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="container">
          <div className="row">
            {footerTop.map((item, index) => (
              <div
                key={index}
                className="col-sm-6 col-md-4 d-flex align-items-center"
              >
                <img src={item.icon} width="80" alt={item.title} />
                <div className="ms-3">
                  <div className={clsx(Styles.footer_title)}>{item.title}</div>
                  <div className={Styles.footer_text}>{item.text}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={clsx(Styles.footerMiddel, "mt-5")}>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className={Styles.footer_title}>customer service</div>
                <ul className=" p-0">
                  {customercare.map((item, index) => (
                    <li
                      key={index}
                      className={clsx(
                        Styles.footer_text,
                        "d-block py-1 text-capitalize"
                      )}
                    >
                      <Link to={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6 col-md-3">
                {" "}
                <div className={Styles.footer_title}>information</div>
                <ul className=" p-0">
                  {information.map((item, index) => (
                    <li
                      key={index}
                      className={clsx(
                        Styles.footer_text,
                        "d-block py-1 text-capitalize"
                      )}
                    >
                      <Link to={item.link}> {item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6 col-md-3">
                {" "}
                <div className={Styles.footer_title}>about us</div>
                <ul className=" p-0">
                  {about.map((item, index) => (
                    <li
                      key={index}
                      className={clsx(
                        Styles.footer_text,
                        "d-block py-1 text-capitalize"
                      )}
                    >
                      <Link to={item.link}> {item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6 col-md-3">
                {" "}
                <div className={Styles.footer_title}>contact us</div>
                <ul className=" p-0">
                  {contact.map((item, index) => (
                    <li
                      key={index}
                      className={clsx(
                        Styles.footer_text,
                        "d-block py-1 text-capitalize d-flex align-items-center "
                      )}
                    >
                      <span>
                        <img src={item.icon} width="15" alt={item.text} />
                      </span>
                      <span className="ms-2">{item.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center socials">
          {social.map((socItem, index) => (
            <Link key={index} to={{ pathname: socItem.link }} target="_blank">
              <img src={socItem.icon} width="25" alt={socItem.name} />
            </Link>
          ))}
        </div>
      </div>

      <div className="bg-white py-3">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="small text-muted">
              Copyright {new Date().getFullYear()} © Teddy's Fresh Market. All
              rights reserved
            </div>
            <Link
              to={{
                pathname:
                  "https://play.google.com/store/apps/details?id=com.teddys.freshmarket",
              }}
              target="_blank"
            >
              <img
                src={googlePlayButton}
                width="110"
                alt="google play button"
              />
            </Link>
            <img src={paymentsImage} width="310" alt="payment methods" />
          </div>
        </div>
      </div>
      <div className="bg-green small text-muted text-center py-2  ">
        <a href="https://www.linkedin.com/in/faisal-kasozi/" target="_blank">
          Meet the Developer
        </a>
      </div>
    </>
  );
};

export default Footer;
