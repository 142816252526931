import { auth, database, storage } from "./firebaseConfig";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import firebaseErrorHandler from "./firebaseErrorHandler";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";

export const authInWithPhoneNumber = async (
  phoneNumber,
  setLoading,
  setCodeOtpSent,
  setErrorMessage
) => {
  setLoading(true);

  renderRecaptcha();

  let appVerifier = window.recaptchaVerifier;

  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmation) => {
      setLoading(false);
      window.confirmation = confirmation;

      setCodeOtpSent(true);
    })
    .catch((error) => {
      setLoading(false);
      let errorMessage = firebaseErrorHandler("recaptha-rendered");
      setErrorMessage(errorMessage);

      window.recaptchaVerifier.clear();
    });
};

export const renderRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    "recap",
    {
      size: "invisible",
      // 'error-callback' : window.recaptchaVerifier.clear()
    },
    auth
  );
};

export const onConfirmationResult = (otpCode, setLoading, setErrorMessage) => {
  let confirmationResult = window.confirmation;
  confirmationResult
    .confirm(otpCode)
    .then((result) => {
      // User signed in successfully.
      const user = result.user;

      setLoading(false);
      // ...
    })
    .catch((error) => {
      let errorMessage = firebaseErrorHandler(error.code);
      setErrorMessage(errorMessage);
    });
};
