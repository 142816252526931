import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

const DeleteAccount = () => {
       useEffect(() => {
         window.scrollTo(0, 0);
       }, []);
  return (
    <>
     <Helmet title={`Delete Account| ${process.env.REACT_APP_SITE_NAME}`} />
      <div className="container mt-5">
        <h3>Delete Your Account</h3>

      <p>
        We're sorry to see you go! If you want to delete your account and
        associated information, please follow these steps:
      </p>

      <ol>
        <li>
          <strong>Login:</strong> Log in to your account on our web app.
        </li>
        <li>
          <strong>Account Settings:</strong> Navigate to your account settings
          or profile page.
        </li>
        <li>
          <strong>Delete Account:</strong> Look for the option to delete your
          account. This might be labeled as "Delete Account," "Close Account,"
          or something similar.
        </li>
        <li>
          <strong>Confirmation:</strong> You may be asked to confirm your
          decision. Follow the on-screen instructions and provide any necessary
          information.
        </li>
        <li>
          <strong>Verification:</strong> In some cases, we may send you a
          verification email or ask for additional information to confirm your
          identity.
        </li>
        <li>
          <strong>Final Confirmation:</strong> Once verified, confirm your
          decision to delete your account. Be aware that this action is
          irreversible.
        </li>
      </ol>

      <p>
        If you encounter any issues or have questions, please contact our
        support team at support@yourwebapp.com.
      </p>
    </div>
    </>
  );
}

export default DeleteAccount